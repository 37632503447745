<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-7 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('payment_method') }}
				</div>
			</div>
			<div class="col-xs-12 col-lg-5 d-flex">
				<div
					class="h4"
					:class="['xs','sm', 'md'].includes(windowWidth) ? 'mb-2 text-left' : 'my-auto ml-auto text-right'">
					<div class="text-primary">
						<span class="font-weight-bold">{{ translate('sponsor') }}: </span>
						<span>{{ sponsor.name }} </span>
						<span>({{ translate('id_word') }}: {{ sponsor.id }})</span>
						<p
							v-if="positionData !== ''">
							<span class="font-weight-bold">{{ translate('parent') }}: </span>
							<span>{{ positionData.parent }} </span>
							<span>({{ translate('leg') }}: {{ translate(positionData.leg) }})</span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li
						v-if="paymentMethods.length > 1"
						class="list-group-item">
						<div class="row">
							<div class="col-12">
								<div class="h4 my-2">
									{{ translate('select_payment_method') }}
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-8">
								<div
									class="btn-group btn-group-lg payment-method"
									role="group">
									<template v-for="item in paymentMethods">
										<b-button
											v-if="item.attributes.method_code_name !== 'cashondelivery'"
											:key="item.id"
											:variant="paymentMethodSelected === item.attributes.method_code_name ? 'primary' : 'light'"
											@click="paymentMethodSelected = item.attributes.method_code_name">
											{{ translate(item.attributes.method_code_name) }}
										</b-button>
									</template>
								</div>
							</div>
						</div>
					</li>
					<form
						@submit.prevent="prepareForStorage()"
						@keydown="clearValidationError($event.target.name);"
						@change="handleAddressFormChange($event.target.name);">
						<li class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('billing_information') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12">
									<b-alert show>
										{{ translate('billing_disclaimer') }}
									</b-alert>
								</div>
								<div class="col-12 mb-3">
									<div class="custom-control custom-checkbox">
										<input
											id="customCheck1"
											v-model="sameAs"
											type="checkbox"
											class="custom-control-input">
										<label
											class="custom-control-label"
											for="customCheck1">{{ translate('same_as_shipping') }}</label>
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.billing.first_name`"
											:label="translate('first_name')"
											:setter-value="form.first_name"
											:errors="validationErrors[`${steps[$route.name].validation}.billing.first_name`]"
											:required="true"
											:disabled="sameAs"
											type="text"
											@dataChanged="form.first_name = $event" />
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.billing.last_name`"
											:label="translate('last_name')"
											:setter-value="form.last_name"
											:errors="validationErrors[`${steps[$route.name].validation}.billing.last_name`]"
											:required="true"
											:disabled="sameAs"
											type="text"
											@dataChanged="form.last_name = $event" />
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.billing.address`"
											:label="translate('address')"
											:setter-value="form.address"
											:errors="validationErrors[`${steps[$route.name].validation}.billing.address`]"
											:required="true"
											:disabled="sameAs"
											type="text"
											@dataChanged="form.address = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.billing.address2`"
											:label="translate('address2')"
											:setter-value="form.address2"
											:errors="validationErrors[`${steps[$route.name].validation}.billing.address2`]"
											:disabled="sameAs"
											type="text"
											@dataChanged="form.address2 = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.billing.postcode`"
											:label="translate('postcode')"
											:setter-value="form.postcode"
											:errors="validationErrors[`${steps[$route.name].validation}.billing.postcode`]"
											:required="true"
											:disabled="sameAs"
											type="text"
											@dataChanged="form.postcode = $event" />
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.billing.city`"
											:label="translate('city')"
											:setter-value="form.city"
											:errors="validationErrors[`${steps[$route.name].validation}.billing.city`]"
											:disabled="sameAs"
											:required="true"
											type="text"
											@dataChanged="form.city = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<label :for="`${steps[$route.name].validation}.billing.country_code`">{{ translate('country') }} <span class="text-danger">*</span></label>
										<select
											:id="`${steps[$route.name].validation}.billing.country_code`"
											v-model="form.country_code"
											:name="`${steps[$route.name].validation}.billing.country_code`"
											:disabled="sameAs || !allowAnotherCountry"
											:class="(typeof validationErrors[`${steps[$route.name].validation}.billing.country_code`] !== 'undefined' ? 'is-invalid' : '')"
											class="form-control">
											<option value="">
												{{ translate('select_country') }}
											</option>
											<template v-if="hasCountries">
												<option
													v-for="item in countries"
													:key="item.id"
													:value="item.attributes.code">
													{{ translate(item.attributes.code.toLowerCase()) }}
												</option>
											</template>
										</select>
										<template v-if="(typeof validationErrors[`${steps[$route.name].validation}.billing.country_code`] !== 'undefined')">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.billing.country_code`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<label :for="`${steps[$route.name].validation}.billing.region_id`">{{ translate('state') }} <span class="text-danger">*</span></label>
										<select
											:id="`${steps[$route.name].validation}.billing.region_id`"
											v-model="form.region_id"
											:name="`${steps[$route.name].validation}.billing.region_id`"
											:disabled="sameAs"
											:class="(typeof validationErrors[`${steps[$route.name].validation}.billing.region_id`] !== 'undefined' ? 'is-invalid' : '')"
											class="form-control">
											<option value="">
												{{ translate('select_state') }}
											</option>
											<template v-if="hasState">
												<option
													v-for="item in states"
													:key="item.id"
													:value="item.id">
													{{ item.attributes.name }}
												</option>
											</template>
										</select>
										<template v-if="(typeof validationErrors[`${steps[$route.name].validation}.billing.region_id`] !== 'undefined')">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.billing.region_id`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
							</div>
						</li>
						<li
							v-show="paymentMethodSelected === 'purchaseorder'"
							class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('cc_information') }}
									</div>
								</div>
							</div>
							<div class="row mx-2">
								<div
									:class="['xs'].includes(windowWidth) ? 'p-0' : ''"
									class="col-12">
									<template v-for="(item, index) in displayedAllowedCC">
										<img
											:key="index"
											:src="require(`@/assets/images/common/cc/logos/${item}.png`)"
											align="right"
											class="text-right mr-2 resize-img">
									</template>
								</div>
							</div>
							<div class="row m-3">
								<div class="col">
									<div class="form-group">
										<form id="cc-form">
											<div class="form-group">
												<label for="cc-name">{{ translate('name_on_card') }} <span class="text-danger">*</span></label>
												<span
													id="cc-name"
													:class="creditCardHasErrors('name') ? 'is-invalid' : ''"
													class="form-field form-control" />
												<template v-if="creditCardHasErrors('name')">
													<p
														v-for="error in creditCardErrors.name.errorMessages"
														:key="error"
														class="custom-invalid-feedback animated fadeIn text-danger mb-0"
														v-text="error" />
												</template>
											</div>
											<div class="form-group">
												<label for="cc-number">{{ translate('card_number') }} <span class="text-danger">*</span></label>
												<span
													id="cc-number"
													:class="creditCardHasErrors('number') ? 'is-invalid' : ''"
													class="form-field form-control" />
												<template v-if="creditCardHasErrors('number')">
													<p
														v-for="error in creditCardErrors.number.errorMessages"
														:key="error"
														class="custom-invalid-feedback animated fadeIn text-danger mb-0"
														v-text="error" />
												</template>
											</div>
											<div class="row">
												<div class="form-group col-12 col-md-4">
													<label for="cc-expiration-month">{{ translate('expiry_month') }} <span class="text-danger">*</span></label>
													<span
														id="cc-expiration-month"
														:class="creditCardHasErrors('expiration_month') ? 'is-invalid' : ''"
														class="form-field form-control" />
													<template v-if="creditCardHasErrors('expiration_month')">
														<p
															v-for="error in creditCardErrors.expiration_month.errorMessages"
															:key="error"
															class="custom-invalid-feedback animated fadeIn text-danger mb-0"
															v-text="error" />
													</template>
												</div>
												<div class="form-group col-12 col-md-4">
													<label for="cc-expiration-year">{{ translate('expiry_year') }} <span class="text-danger">*</span></label>
													<span
														id="cc-expiration-year"
														:class="creditCardHasErrors('expiration_year') ? 'is-invalid' : ''"
														class="form-field form-control" />
													<template v-if="creditCardHasErrors('expiration_year')">
														<p
															v-for="error in creditCardErrors.expiration_year.errorMessages"
															:key="error"
															class="custom-invalid-feedback animated fadeIn text-danger mb-0"
															v-text="error" />
													</template>
												</div>
												<div class="form-group col-12 col-md-4">
													<label for="cc-cvc">{{ translate('cvv') }} <span class="text-danger">*</span></label>
													<span
														id="cc-cvc"
														:class="creditCardHasErrors('security_code') ? 'is-invalid' : ''"
														class="form-field form-control" />
													<template v-if="creditCardHasErrors('security_code')">
														<p
															v-for="error in creditCardErrors.security_code.errorMessages"
															:key="error"
															class="custom-invalid-feedback animated fadeIn text-danger mb-0"
															v-text="error" />
													</template>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</li>
						<li
							v-show="paymentMethodSelected === 'checkmo'"
							class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('cash_payment') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12">
									<b-alert
										show
										variant="warning"
										class="h5">
										<b>{{ translate('important_note').toUpperCase() }}</b>: {{ translate('international_wires_only') }}
									</b-alert>
								</div>
							</div>
						</li>
					</form>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 ml-auto my-4 text-right">
				<div
					class="btn-group w-100"
					role="group">
					<b-button
						:disabled="!!preparing"
						variant="light"
						size="lg"
						@click="nextStep(true)">
						{{ translate('previous') }}
					</b-button>
					<b-button
						:disabled="validating || !!preparing"
						variant="primary"
						size="lg"
						@click="prepareForStorage()">
						<i
							v-if="validating"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue_to_confirm') }}
					</b-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry } from '@/settings/AddressBook';
import { ALLOWEDCC_BY_COUNTRY } from '@/settings/CreditCard';
import { TIMEZONE } from '@/settings/General';
import WindowSizes from '@/mixins/WindowSizes';
import InputText from '@/components/InputText';
import Country from '@/util/Country';
import Cart from '@/util/Cart';
import CommonMix from './mixins/Common';

export default {
	name: 'RegisterOldPaymentMethod',
	components: { InputText },
	mixins: [CommonMix, WindowSizes],
	data() {
		return {
			countryData: new Country(),
			stateData: new Country(),
			state: new Country(),
			countryIso: new Country(),
			paymentMethodData: new Cart(),
			paymentMethodSelected: 0,
			sameAs: false,
			sponsor: {
				id: '',
				name: '',
			},
			identityId: null,
			type: '',
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
			},
			paymentForm: {},
			allowAnotherCountry,
			alert: new this.$Alert(),
			WindowSizes,
			creditCardErrors: {},
			lacoreFailed: false,
			paymentToken: null,
			timezone: TIMEZONE,
		};
	},
	computed: {
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.country);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		paymentMethods() {
			try {
				return this.paymentMethodData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		displayedAllowedCC() {
			return ALLOWEDCC_BY_COUNTRY[this.country] ?? ALLOWEDCC_BY_COUNTRY.default;
		},
	},
	watch: {
		sameAs: function sameAs(value) {
			if (value) {
				this.clearValidationError(true);
				const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
				const { shipping } = this.getStepInformation(step);
				Object.keys(shipping.shipping_address).forEach((property) => {
					if (property !== 'signature_required') this.form[property] = shipping.shipping_address[property];
				});
			} else {
				Object.keys(this.form).forEach((key) => {
					this.form[key] = '';
				});
				this.form.country_code = this.country;
			}
		},
	},
	mounted() {
		this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry() }).then(() => {
			if (this.paymentMethodSelected === 0) {
				this.paymentMethodSelected = this.paymentMethods[0].attributes.method_code_name;
			}
		}).finally(() => {
			// Initialize Lacore payment form
			let timeout = 1000;
			if (this.paymentMethodSelected === 'purchaseorder') {
				timeout = 3000;
			}

			// Waiting for LP Form
			this.preparing = true;
			setTimeout(() => {
				this.preparing = false;
			}, timeout);
		});
	},
	created() {
		this.countryData.getCountries();
		this.getGeneralInformation();
		this.getStoredInformation();
		this.form.country_code = this.country;
		this.stateData.getStates(this.form.country_code);
		this.setStoredSameAs();
		return null;
	},
	methods: {
		setStoredSameAs() {
			if (this.getStepInformation().payment !== undefined) {
				const { same_as_shipping: sameAs } = { ...this.getStepInformation().payment.billing };
				if (sameAs === true) {
					const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
					const { shipping } = this.getStepInformation(step);
					Object.keys(shipping.shipping_address).forEach((key) => {
						this.form[key] = shipping.shipping_address[key];
					});
				}
				this.sameAs = sameAs;
			}
		},
		getGeneralInformation() {
			const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
			const previous = this.getStepInformation(step);
			const {
				sponsor, identity_id: identityId,
			} = previous;
			try {
				const { shipping_address: shippingAddress } = previous.shipping;
				if (typeof shippingAddress === 'undefined' || typeof sponsor === 'undefined') {
					this.nextStep(true);
					return null;
				}
			} catch (error) {
				this.nextStep(true);
				return null;
			}
			this.sameAs = true;
			this.identityId = identityId;
			this.getSponsorInfo(sponsor).then((response) => {
				Object.keys(this.sponsor).forEach((value) => {
					this.sponsor[value] = response[value];
				});
			});
			return null;
		},
		getStoredInformation() {
			const { payment } = { ...this.getStepInformation() };
			if (typeof payment !== 'undefined' && typeof payment.payment_method !== 'undefined') {
				this.paymentMethodSelected = payment.payment_method.name;
			}
			try {
				const { billing: data } = payment;
				const { country_code: countryCode } = data;
				Object.keys(this.form).forEach((key) => {
					if (typeof data[key] !== 'undefined') {
						this.form[key] = data[key];
					}
				});
				if (countryCode !== this.country) {
					this.form.region_id = '';
				}
			} catch (error) {
				return null;
			}
			return null;
		},
		async prepareForStorage() {
			this.preparing = true;
			const billing = { ...this.form, same_as_shipping: this.sameAs };

			let validation = {
				step: this.steps[this.$route.name].validation, sponsor: this.sponsor.id, identity_id: this.identityId,
			};

			if (this.paymentMethodSelected === 'purchaseorder') {
				// await this.handleLacoreResponse();
			}
			const paymentMethod = { name: this.paymentMethodSelected, token: this.paymentToken };
			if (this.paymentMethodSelected === 'checkmo') {
				this.lacoreFailed = false;
			}

			const payment = { billing, payment_method: paymentMethod };
			validation = { ...validation, payment };
			this.validateStep(validation).then((response) => {
				validation = { ...validation, card_data: { ...response.response } };
				if (!this.lacoreFailed) {
					this.saveStep(validation);
				}
			}).catch(() => {
				if (typeof this.validationErrors.identity_id !== 'undefined') {
					this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
				}
				if (typeof this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`] !== 'undefined' && !this.lacoreFailed) {
					this.creditCardErrors.number.errorMessages = this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`];
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'billing.billing.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
		},
		creditCardHasErrors(field) {
			if (typeof this.creditCardErrors[field] !== 'undefined' && this.creditCardErrors[field].errorMessages.length > 0) {
				return true;
			}
			return false;
		},
	},
};
</script>
